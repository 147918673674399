import { hotelInfoCheck } from '../infoCheck/hotelInfoCheck'
import getRole from '../auth/getRole'
import {modal} from '@tian-libs/modal'

export default defineNuxtRouteMiddleware(async (to, from) => {
    
    const userRole = getRole()
    console.log("userRole", userRole)
    if(!userRole.includes('酒店')){
        return
    }
    if(to.path.startsWith('/login')
    || to.path.startsWith('/password-reset')
    || to.path.startsWith('/register')
    || to.path.startsWith('/jiudian/jcsjtb')
    ){
        return
    }

    const hotelInfoCheckResult = await hotelInfoCheck()
    
    if(hotelInfoCheckResult.length > 0){
        modal.alert('请先完善必填酒店信息后，再使用其他功能（即将跳转至填写页面）:' + hotelInfoCheckResult.join(', '))
        return navigateTo('/jiudian/jcsjtb', { redirectCode: 301 })
    }
})