const exemptedPaths = [
    '/login', 
    '/docs/disclaimer', 
    '/docs/privacy', 
    '/docs/tos',
    '/password-reset',
    '/register',
]

export default defineNuxtRouteMiddleware(async (to, from) => {

    if (to.matched.length === 0) {
      return navigateTo('/start', { redirectCode: 301 })
    }
    
    const jwtCookie = useCookie('jwt')
    console.log('jwtCookie', jwtCookie.value)
    
    if(to.path=='/login') return

    // go to login if no logged in
    if( !jwtCookie.value){
        for(const path of exemptedPaths){
            if(to.path.startsWith(path)){
                return
            }
        }

        localStorage.setItem('lastPath', to.path)
        localStorage.setItem('lastPathQuery', JSON.stringify(to.query))
        // alert('navigating to login')
        return navigateTo({path:'/login'})
    }

})


  