import { dataformPassed } from '../infoCheck/dataformPassed'
import {modal} from '@tian-libs/modal'
import getRole from '../auth/getRole'

export default defineNuxtRouteMiddleware(async (to, from) => {
    // console.log("to", to, to.path.startsWith('/jiudian/ydsjtb'))
    
    const userRole = getRole()
    console.log("userRole", userRole)
    if(!userRole.includes('酒店')){
        return
    }
    
    if(to.path.startsWith('/jiudian/jcsjtb')
    || to.path.startsWith('/jiudian/ydsjtb')
    || to.path.startsWith('/login')
    || to.path.startsWith('/password-reset')
    || to.path.startsWith('/register')
    ){
        return
    }
    const dataformPassedResult = await dataformPassed()
    console.log("dataformPassedResult", dataformPassedResult)
    if(dataformPassedResult == "未提交"){
        modal.alert('请先完成数据填报，再使用其他功能（即将跳转至填报页面）')
        return navigateTo('/jiudian/ydsjtb', { redirectCode: 301 })
    }
    if(dataformPassedResult == "未通过"){
        modal.alert('数据填报未通过，请重新填报（即将跳转至填报页面）')
        return navigateTo('/jiudian/ydsjtb', { redirectCode: 301 })
    }
    if(dataformPassedResult == "未审核"){
        modal.alert('数据填报未审核，请等待审核（即将跳转至填报页面）')
        return navigateTo('/jiudian/ydsjtb', { redirectCode: 301 })
    }

})
