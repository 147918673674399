
import { useUserInfoStore } from "~/stores/userInfoStore"
export default () => {
    const userInfoStore = useUserInfoStore()
    
    try{
        const isXiehui = userInfoStore.info.roles.find(x=>{
            return x.name == "协会"
        })
        if (isXiehui){
            return "协会"
        }
        const isXiehuiTrial = userInfoStore.info.roles.find(x=>{
            return x.name == "协会试用"
        })
        if (isXiehuiTrial){
            return "协会试用"
        }
        const isJiudianTrial = userInfoStore.info.roles.find(x=>{
            return x.name == "酒店试用"
        })
        if (isJiudianTrial){
            return "酒店试用"
        }
        const isJiudian = userInfoStore.info.roles.find(x=>{
            return x.name == "酒店"
        })
        if (isJiudian){
            return "酒店"
        }
        return ""
    }catch(e){
        return ""
    }
}