
export const getReportPeriods = () => {
    const months = [] as string[]
    const lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)
    let year = lastMonth.getFullYear()
    let month = lastMonth.getMonth() + 1 // JavaScript months are 0-based

    for (let i = 0; i < 12; i++) {
        if (month == 0) {
            month = 12
            year--
        }

        // Format month with leading zero if needed
        const formattedMonth = month.toString() //.padStart(2, '0')
        months.push(`${year}-${formattedMonth}`)
        
        month--
    }

    // months.reverse()
    return months
}