import getRole from '../auth/getRole'
import {useUserInfoStore} from '../stores/userInfoStore'

export const hotelInfoCheck = ():string[] => {
    const userRole = getRole()
    console.log("userRole", userRole)
    if(!userRole){
        return []
    }
    if(!userRole.includes('酒店')){
        return []
    }
    const hotel = useUserInfoStore().info.hotel
    console.log("hotel", hotel)

    let requiredFields:string[] = []

    if(hotel && hotel.name == ''){
        requiredFields.push('酒店名称')
    }
    if(hotel && hotel.star_rating == ''){
        requiredFields.push('酒店档次')
    }
    if(hotel && hotel.address == ''){
        requiredFields.push('地址')
    }
    if(hotel && hotel.service_level == ''){
        requiredFields.push('服务范围')
    }
    if(hotel && hotel.location == ''){
        requiredFields.push('位置')
    }
    if(hotel && hotel.hotel_style == ''){
        requiredFields.push('酒店类型')
    }
    if(hotel && hotel.key_count == ''){
        requiredFields.push('客房总数')
    }
    if(hotel&&(!hotel.brand)){
        requiredFields.push('品牌')
    }
    if(hotel&&(!!hotel.brand)&&(hotel.brand.name == '')){
        requiredFields.push('品牌')
    }
    if(hotel && ((!hotel.comp_set) || hotel.comp_set.length == 0)){
        requiredFields.push('竞争组群')
    }
    return requiredFields

}